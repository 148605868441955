import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import {
  IpToZip,
  ZipManagerV1,
  ZipResponse,
} from '@volkswagen-onehub/zip-manager';
import { History } from 'history';
import isBrowser from 'is-browser';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { SalesEventOffersIds, SpecialEventConfig } from '../';
import { MappedTrimNameModel } from '../config/mapped-trim-name';
import { ModelOrder } from '../config/model-order';
import ErrorCodes from '../error-handling/error-codes';
import ErrorMessages from '../error-handling/error-mesagges';
import IncentiveConstants from '../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../hooks-store/store';
import {
  DealerModel,
  OfferInformation,
  TypeMap,
} from '../hooks-store/typings/incentive-store';
import { FeatureAppTrackingManager } from '../hooks/use-tracking-manager';
import { ErrorHandlingModel } from '../store/fetch/models-config';
import { getStringifiedServiceConfigsServiceConfig } from '../utils/get-stringified-service-configs-service-config';

import { StoreModel } from '../hooks-store/typings/store';
import { useFeatureServices } from '../hooks/use-feature-services';
import { useHistory } from '../hooks/use-history';
import { useLogger } from '../hooks/use-logger';
import { useUniversalEffect } from '../hooks/use-universal-effect';
import { onHandlerFilterSeachLoad } from '../tagging/tagging-helpers';
import { FeatureAppModelConfig } from '../typing/main';
import { ModelConfig } from '../typing/model-config';
import { AppConstants } from '../utils/app-constants';
import { fetchModelConfig } from './fetch-model-config';
import { getAorDealer, mapOffersData } from './format';
import { fetchDealersByZipCode } from './graphql/fetch-dealer-by-zipcode';
import { fetchOffersByZipCode } from './graphql/fetch-offers-by-zipcode';
import { ServiceConfig } from './graphql/typing';

interface FetchOptions {
  readonly ipToZip?: IpToZip;
  readonly graphQLServer?: ServiceConfig;
  readonly page?: string;
  readonly zip?: string;
  readonly localeService: LocaleServiceV1;
  readonly trackingManager?: FeatureAppTrackingManager;
  readonly serviceConfigsServiceConfig?: string;
  readonly zipManager?: ZipManagerV1;
  readonly history: History;
  readonly faServicesUrl?: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly modelOrder?: ModelOrder[];
  readonly modelsConfig?: FeatureAppModelConfig;
  readonly mappedTrimName?: MappedTrimNameModel;
  readonly offerId?: number;
  readonly isNationalOffer?: boolean;
  readonly specialEvent?: SpecialEventConfig;
  readonly salesEventOffersIds?: SalesEventOffersIds;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
  readonly updateStoreByZip?: (
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel | null,
    history: History,
    zip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig?: ModelConfig,
    offerId?: Number,
    specialEvent?: SpecialEventConfig,
    salesEventOffersIds?: SalesEventOffersIds,
    modelKey?: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) => StoreModel;
  readonly setInitialData?: (
    page: string,
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel | null,
    history: History,
    zip: string,
    ipZip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    specialEvent?: SpecialEventConfig,
    salesEventOffersIds?: SalesEventOffersIds,
    modelKey?: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) => StoreModel;
  successResultCallback?: (store: StoreModel) => void;
}

const getDealersError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchDealersError,
        errorMessage: ErrorMessages.FetchDealersError,
      },
      ...errorHandling,
    ],
    'errorCode',
  );

const getOffersError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchOffersError,
        errorMessage: ErrorMessages.FetchOffersError,
      },
      ...errorHandling,
    ],
    'errorCode',
  );

async function validateZipCodeByDealers({ graphQLServer, zip }: any) {
  try {
    const dealersResults: any = await fetchDealersByZipCode({
      zipCode: zip,
      graphQLServer,
    });

    if (dealersResults.errorStatus || !dealersResults.data.dealers.length) {
      return { isValid: false };
    } else {
      return { isValid: true };
    }
  } catch (error) {
    return { isValid: false };
  }
}
/**
 * Fetch all the necessary data from SSR to start the app
 * @param page pageName
 * @param zip Postal code
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param modelOrder Obj form cms configuration to sort the offers categories
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export const fetchInitialDataSSR = (
  page: string,
  zip: string,
  faServicesUrl: string,
  mockIds?: string,
  mocksBaseUrl?: string,
  modelOrder?: ModelOrder[],
  mappedTrimName?: MappedTrimNameModel,
  specialEvent?: SpecialEventConfig,
  modelKey?: string,
  trim?: string,
  isTrimLevel?: boolean,
): StoreModel | undefined => {
  // Call different services used to apply fetches and update the store data
  const logger = useLogger();
  const history = useHistory();

  // App store
  const [
    store,
    { [IncentiveConstants.SetInitialData]: setInitialData },
  ] = useStore(false);

  const { salesEventOffersIds } = store.fetchedSharedConfigs;

  const {
    's2:async-ssr-manager': asyncSsrManager,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
  } = useFeatureServices();

  const {
    configs: { 'iptozip': ipToZip, 'graphql-server': graphQLServer },
  } = serviceConfigProvider;

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );

  let zipAux = zip ? zip : '33040';

  // Custom useEffect
  useUniversalEffect(() => {
    if (store && store.status) {
      if (!isBrowser && store.status === AppConstants.StoreStatusLoaded) {
        return;
      }

      if (store.status === AppConstants.StoreStatusFailed && store.ssr) {
        logger.warn('Offers SSR error detected, trying to fetch data again.');
      } else {
        return;
      }
    }
    let fetchedDealers: DealerModel[];
    let fetchedDealer: DealerModel | null;
    let fetchedOffers: TypeMap<OfferInformation>;
    let errorHandling: ErrorHandlingModel[] = [];

    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(
        Promise.resolve(
          fetchModelConfig({
            serviceConfigsServiceConfig,
            mockIds,
            mocksBaseUrl,
            faServicesUrl,
          })
            .then(result => {
              const modelsConfig = result;
              if (isEmpty(zip)) {
                // Get zip from IP
                asyncSsrManager.scheduleRerender(
                  Promise.resolve(
                    zipManager.getZipByIp(true, ipToZip as IpToZip),
                  ).then(
                    zipIpResp => {
                      zipAux = zipIpResp.status
                        ? (zipIpResp as ZipResponse).response
                        : '33040'; // set a default since the iptozip call failed and we need a zip to load

                      asyncSsrManager.scheduleRerender(
                        Promise.all([
                          validateZipCodeByDealers({
                            zip: zipAux,
                            graphQLServer,
                          }).then(() => {
                            asyncSsrManager.scheduleRerender(
                              Promise.all([
                                fetchOffersByZipCode({
                                  zipCode: zipAux,
                                  graphQLServer,
                                })
                                  .then((result: any) => {
                                    if (result.errorStatus) {
                                      fetchedOffers = {};
                                    } else {
                                      fetchedOffers = mapOffersData(
                                        result.data,
                                        modelOrder,
                                        mappedTrimName,
                                        modelsConfig,
                                      );
                                    }
                                    if (result.errorStatus) {
                                      errorHandling = getOffersError(
                                        errorHandling,
                                      );
                                    }
                                  })
                                  .catch((error: Error) => {
                                    fetchedOffers = {};
                                    errorHandling = getOffersError(
                                      errorHandling,
                                    );
                                    logger.error(error);
                                  }),
                                fetchDealersByZipCode({
                                  zipCode: zipAux,
                                  graphQLServer,
                                })
                                  .then((result: any) => {
                                    if (result.errorStatus) {
                                      fetchedDealers = [];
                                      fetchedDealer = null;
                                    } else {
                                      fetchedDealers = result.data.dealers;
                                      fetchedDealer = getAorDealer(
                                        result.data.dealers,
                                      );
                                    }
                                    if (result.errorStatus) {
                                      errorHandling = getDealersError(
                                        errorHandling,
                                      );
                                    }
                                  })
                                  .catch((error: Error) => {
                                    fetchedDealers = [];
                                    fetchedDealer = null;
                                    errorHandling = getDealersError(
                                      errorHandling,
                                    );
                                    logger.error(error);
                                  }),
                              ]).then(() => {
                                setInitialData(
                                  page,
                                  fetchedOffers,
                                  fetchedDealers,
                                  fetchedDealer,
                                  history,
                                  zipAux,
                                  zipAux,
                                  errorHandling,
                                  modelsConfig,
                                  specialEvent,
                                  salesEventOffersIds,
                                  modelKey,
                                  trim,
                                  isTrimLevel,
                                );
                              }),
                            );
                          }),
                        ]),
                      );
                    },
                    value => {
                      console.log('Errors fetching ipzip:' + value);
                      console.log('Using default value: 33040');
                      zipAux = '33040';
                      asyncSsrManager.scheduleRerender(
                        Promise.all([
                          fetchOffersByZipCode({
                            zipCode: zipAux,
                            graphQLServer,
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedOffers = {};
                              } else {
                                fetchedOffers = mapOffersData(
                                  result.data,
                                  modelOrder,
                                  mappedTrimName,
                                  modelsConfig,
                                );
                              }
                              if (result.errorStatus) {
                                errorHandling = getOffersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedOffers = {};
                              errorHandling = getOffersError(errorHandling);
                              logger.error(error);
                            }),
                          fetchDealersByZipCode({
                            zipCode: zipAux,
                            graphQLServer,
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedDealers = [];
                                fetchedDealer = null;
                              } else {
                                fetchedDealers = result.data.dealers;
                                fetchedDealer = getAorDealer(
                                  result.data.dealers,
                                );
                              }
                              if (result.errorStatus) {
                                errorHandling = getDealersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedDealers = [];
                              fetchedDealer = null;
                              errorHandling = getDealersError(errorHandling);
                              logger.error(error);
                            }),
                        ])
                          .then(() => {
                            setInitialData(
                              page,
                              fetchedOffers,
                              fetchedDealers,
                              fetchedDealer,
                              history,
                              zipAux,
                              zipAux,
                              errorHandling,
                              modelsConfig,
                              specialEvent,
                              salesEventOffersIds,
                              modelKey,
                              trim,
                              isTrimLevel,
                            );
                          })
                          .catch(error => {
                            logger.error('Error Initializing data', error);
                          }),
                      );
                    },
                  ),
                );
              } else {
                zipAux = zip;
                asyncSsrManager.scheduleRerender(
                  Promise.all([
                    validateZipCodeByDealers({
                      zip: zipAux,
                      graphQLServer,
                    }).then(() => {
                      asyncSsrManager.scheduleRerender(
                        Promise.all([
                          fetchOffersByZipCode({
                            zipCode: zipAux,
                            graphQLServer,
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedOffers = {};
                              } else {
                                fetchedOffers = mapOffersData(
                                  result.data,
                                  modelOrder,
                                  mappedTrimName,
                                  modelsConfig,
                                );
                              }
                              if (result.errorStatus) {
                                errorHandling = getOffersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedOffers = {};
                              errorHandling = getOffersError(errorHandling);
                              logger.error(error);
                            }),
                          fetchDealersByZipCode({
                            zipCode: zipAux,
                            graphQLServer,
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedDealers = [];
                                fetchedDealer = null;
                              } else {
                                fetchedDealers = result.data.dealers;
                                fetchedDealer = getAorDealer(
                                  result.data.dealers,
                                );
                              }
                              if (result.errorStatus) {
                                errorHandling = getDealersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedDealers = [];
                              fetchedDealer = null;
                              errorHandling = getDealersError(errorHandling);
                              logger.error(error);
                            }),
                        ])
                          .then(() => {
                            setInitialData(
                              page,
                              fetchedOffers,
                              fetchedDealers,
                              fetchedDealer,
                              history,
                              zipAux,
                              '',
                              errorHandling,
                              modelsConfig,
                              specialEvent,
                              salesEventOffersIds,
                              modelKey,
                              trim,
                              isTrimLevel,
                            );
                          })
                          .catch(error => {
                            logger.error('Error initialing data', error);
                          }),
                      );
                    }),
                  ]),
                );
              }
            })
            .catch(error => {
              logger.debug(error);
            }),
        ),
      );
    }
  }, []);
  return store?.status === AppConstants.StoreStatusLoaded ? store : undefined;
};

/**
 * Fetch all the necessary data from CSR to start the app
 * @param page Page name
 * @param zip Postal Code
 * @param localeService Gobal service
 * @param serviceConfigsServiceConfig Global service
 * @param zipManager Zip service
 * @param setInitialData Store function to set data
 * @param history Router history
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param modelOrder Obj form cms configuration to sort the offers categories
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export async function fetchInitialDataCSR({
  page,
  zip,
  zipManager,
  trackingManager,
  setInitialData,
  history,
  modelOrder,
  mappedTrimName,
  ipToZip,
  graphQLServer,
  isNationalOffer,
  specialEvent,
  modelKey,
  trim,
  isTrimLevel,
  salesEventOffersIds,
  modelsConfig,
}: FetchOptions): Promise<any> {
  let zipAux = zip ? zip : '33040';
  let fetchedDealers: DealerModel[];
  let fetchedDealer: DealerModel | null;
  let fetchedOffers: TypeMap<OfferInformation>;
  let errorHandling: ErrorHandlingModel[] = [];
  let isNational =
    (isEmpty(zip) && page == AppConstants.Tier1SimplifiedVersion) ||
    isNationalOffer;

  if (isNational) {
    //Skip dealers data fetch
    fetchedDealers = [];
    fetchedDealer = null;
    zipAux = '';
  } else if (isEmpty(zip)) {
    if (zipManager) {
      try {
        const zipResponse = await zipManager.getZipByIp(
          true,
          ipToZip as IpToZip,
        );

        zipAux = zipResponse.status
          ? (zipResponse as ZipResponse).response
          : '33040'; // set a default since the iptozip call failed and we need a zip to load
      } catch (error) {
        zipAux = '33040';
        console.log('Error: zipManager request', error);
      }
    } else {
      zipAux = '33040';
    }
  }

  let dataFetch = [
    fetchOffersByZipCode({
      zipCode: zipAux,
      graphQLServer,
    })
      .then((result: any) => {
        if (result.errorStatus) {
          fetchedOffers = {};
        } else {
          fetchedOffers = mapOffersData(
            result.data,
            modelOrder,
            mappedTrimName,
            modelsConfig,
            AppConstants.AllOfferModelKey,
          );
        }
        if (result.errorStatus) {
          errorHandling = getOffersError(errorHandling);
        }
      })
      .catch((error: Error) => {
        fetchedOffers = {};
        errorHandling = getOffersError(errorHandling);
        console.log(error);
      }),
  ];

  if (!isNational) {
    dataFetch.push(
      fetchDealersByZipCode({
        zipCode: zipAux,
        graphQLServer,
      })
        .then((result: any) => {
          if (result.errorStatus) {
            fetchedDealers = [];
            fetchedDealer = null;
          } else {
            fetchedDealers = result.data.dealers;
            fetchedDealer = getAorDealer(result.data.dealers);
          }
          if (result.errorStatus) {
            errorHandling = getDealersError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedDealers = [];
          fetchedDealer = null;
          errorHandling = getDealersError(errorHandling);
          console.log(error);
        }),
    );
  }

  Promise.all(dataFetch).then(() => {
    if (setInitialData) {
      const updatedStore = setInitialData(
        page as string,
        fetchedOffers,
        fetchedDealers,
        fetchedDealer,
        history,
        zipAux,
        '',
        errorHandling,
        modelsConfig,
        specialEvent,
        salesEventOffersIds,
        modelKey,
        trim,
        isTrimLevel,
      );
      // Tracking when page was loaded.
      trackingManager &&
        onHandlerFilterSeachLoad(trackingManager, updatedStore);
    }
  });

  return null;
}

/**
 * Update the app data after changing the zip code
 * @param zip Postal Code
 * @param localeService Gobal service
 * @param serviceConfigsServiceConfig Global service
 * @param updateStoreByZip Store function to set data
 * @param zipManager Zip service
 * @param history Router history
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param modelOrder Obj form cms configuration to sort the offers categories
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export async function updateStoreData({
  zip,
  serviceConfigsServiceConfig,
  updateStoreByZip,
  history,
  faServicesUrl,
  mockIds,
  mocksBaseUrl,
  modelOrder,
  mappedTrimName,
  successResultCallback,
  offerId,
  specialEvent,
  graphQLServer,
  salesEventOffersIds,
  modelKey,
  trim,
  isTrimLevel,
  modelsConfig,
}: FetchOptions): Promise<any> {
  let fetchedDealers: DealerModel[];
  let fetchedDealer: DealerModel | null;
  let fetchedOffers: TypeMap<OfferInformation>;
  let errorHandling: ErrorHandlingModel[] = [];

  modelsConfig = !modelsConfig
    ? await fetchModelConfig({
        faServicesUrl,
        serviceConfigsServiceConfig,
        mockIds,
        mocksBaseUrl,
      })
    : modelsConfig;

  // fetchOffers promise declaration
  const fetchOffersPromise = fetchOffersByZipCode({
    zipCode: zip,
    graphQLServer,
  })
    .then((result: any) => {
      if (result.errorStatus) {
        fetchedOffers = {};
      } else {
        fetchedOffers = mapOffersData(
          result.data,
          modelOrder,
          mappedTrimName,
          modelsConfig,
        );
      }
      if (result.errorStatus) {
        errorHandling = getOffersError(errorHandling);
      }
    })
    .catch((error: Error) => {
      fetchedOffers = {};
      errorHandling = getOffersError(errorHandling);
      console.log(error);
    });

  // fetchDealers promise declaration
  const fetchedDealerPromise = fetchDealersByZipCode({
    zipCode: zip,
    graphQLServer,
  })
    .then((result: any) => {
      if (result.errorStatus) {
        fetchedDealers = [];
        fetchedDealer = null;
      } else {
        fetchedDealers = result.data.dealers;
        fetchedDealer = getAorDealer(result.data.dealers);
      }
      if (result.errorStatus) {
        errorHandling = getDealersError(errorHandling);
      }
    })
    .catch((error: Error) => {
      fetchedDealers = [];
      fetchedDealer = null;
      errorHandling = getDealersError(errorHandling);
      console.log(error);
    });

  Promise.all([fetchOffersPromise, fetchedDealerPromise]).then(() => {
    if (updateStoreByZip) {
      const updatedStore = updateStoreByZip(
        fetchedOffers,
        fetchedDealers,
        fetchedDealer,
        history,
        zip || '',
        errorHandling,
        modelsConfig,
        offerId,
        specialEvent,
        salesEventOffersIds,
        modelKey,
        trim,
        isTrimLevel,
      );
      successResultCallback && successResultCallback(updatedStore);
    }
  });
  return null;
}
