import { ServerRequestV1 } from '@feature-hub/server-request';
import {
  ServiceConfigProviderV1,
  ServiceConfigurationV1,
} from '@volkswagen-onehub/service-config-provider';

interface SharedConfigurations {
  urlPath: string;
  urlOrigin: string;
}

function getServiceOrigin(
  serviceConfig: ServiceConfigurationV1,
  serverRequest: ServerRequestV1 | undefined,
): string {
  if (serviceConfig.urlOrigin) {
    return serviceConfig.urlOrigin;
  }

  if (serverRequest) {
    return new URL(serverRequest.url).origin;
  }

  return window.location.origin;
}

export function getOffersSharedConfigurations(
  serviceConfigProvider: ServiceConfigProviderV1,
  serverRequest?: ServerRequestV1,
): SharedConfigurations {
  const offersSharedConfigs =
    serviceConfigProvider.configs['offersSharedConfigs'];

  if (!offersSharedConfigs) {
    return {
      urlOrigin: '',
      urlPath: '',
    };
  }

  const urlOrigin = getServiceOrigin(offersSharedConfigs, serverRequest);
  const { urlPath } = offersSharedConfigs;

  return { urlOrigin, urlPath: urlPath ?? '' };
}
