import React from 'react';
import isBrowser from 'is-browser';
// import { RestLink } from 'apollo-link-rest';
import { ApolloProvider } from '@apollo/client/react';
import { useFeatureAppConfig } from '../hooks/use-feature-app-config';
import { useFeatureServices } from '../hooks/use-feature-services';
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { CarModelConfig } from '../typing/main';
import { sanitizeHtmlText, sanitizeLocaleType } from '../utils/general';

interface DataServerProps {
  serverUrl?: string;
  modelsConfig: Record<string, CarModelConfig>;
  children: React.ReactNode;
}

const GRAPHQL_PROVIDER_NAME = 'graphql-server';
export const DataServer: React.FC<DataServerProps> = (
  props: DataServerProps,
) => {
  const { children, modelsConfig } = props;
  const { trimNamesMap = {} } = useFeatureAppConfig();
  const { configs } = useFeatureServices()['service-config-provider'];
  const {
    graphQLServerUrl,
    graphQLConfigProviderName = GRAPHQL_PROVIDER_NAME,
  } = useFeatureAppConfig();

  // TODO: GRAPHQL DATA
  const graphQLEnvServer = configs[graphQLConfigProviderName]
    ? new URL(
        configs[graphQLConfigProviderName]?.urlPath || '/',
        configs[graphQLConfigProviderName]?.urlOrigin,
      ).toString()
    : '';
  console.log(graphQLEnvServer || graphQLServerUrl);
  const graphQLServer = graphQLEnvServer || graphQLServerUrl || '';
  !graphQLServer && console.error('No value provided for graphQLServer');

  const httpLink = createHttpLink({
    uri: graphQLServer,
    fetch: fetch,
  });

  // Set `RestLink` with your endpoint
  //   if(!faServicesUrl) {
  //     if (configs['fa-services']) {
  //       faServicesUrl = configs['fa-services'].urlOrigin
  //     }
  //   }
  // const httpLink = new RestLink({
  //   uri: faServicesUrl,
  // });

  const typePolicies = {
    Query: {
      fields: {
        modelByCarlineId: {
          read(_, args) {
            const { variables } = args;
            return (modelsConfig && modelsConfig[variables.id]) || modelsConfig;
          },
        },
      },
    },
    vwcomSpecialOfferWrapper: {
      fields: {
        offerLegal: {
          read(_, { readField }) {
            const legal = readField('dealLegal');
            return sanitizeHtmlText(legal);
          },
        },
        offerCopy: {
          read(_, { readField }) {
            const copy = readField('dealCopy');
            return sanitizeHtmlText(copy);
          },
        },
        localeType: {
          read(_, { readField }) {
            const locale = readField('dealLocaleType');
            return sanitizeLocaleType(locale);
          },
        },
        offerTitle: {
          read(_, { readField }) {
            const title = readField('dealTitle');
            return sanitizeHtmlText(title);
          },
        },
        offerSubtitle: {
          read(_, { readField }) {
            const title = readField('dealSubtitle');
            return sanitizeHtmlText(title);
          },
        },
        trimInventoryName: {
          read(_, { readField }) {
            const years = readField('dealCarYear');
            const models = readField('dealCarModel');
            const trims = readField('dealCarTrim');

            const trim = trims?.length && trims[0].toLowerCase();
            const model = models?.length && models[0].toLowerCase();
            const year = years?.length && years[0].toLowerCase();
            if (trim !== 'all') {
              const trimKey = `${year}-${model}-${trim}`;
              return trimKey && trimNamesMap && trimNamesMap[trimKey]
                ? trimNamesMap[trimKey]?.trimInventoryName
                : '';
            }
            return '';
          },
        },
      },
    },
  };

  const cache = new InMemoryCache({
    typePolicies,
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: cache,
    ssrMode: !isBrowser,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
