import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledStickyHeaderProps {
  active: boolean;
  stickyPosition?: number;
  noPadding?: boolean;
  activeModal?: boolean;
  bottomShadow?: boolean;
}
export const StyledPositionWrapper: any = styled.div<StyledStickyHeaderProps>`
  ${({ active, noPadding, activeModal, theme }: any) =>
    !active && !activeModal
      ? `
        padding-left: ${noPadding ? '0' : '30px'};
        @media (min-width: ${Breakpoints.b560}px) {
          padding-right: ${theme.size.grid002};
          padding-left: ${noPadding ? '0' : theme.size.grid002};
        }
        @media (min-width: ${Breakpoints.b1280}px) {
          padding-right: ${theme.size.grid002};
          padding-left: ${noPadding ? '0' : theme.size.grid002};
        }
      `
      : `width: 100%;
      ${
        noPadding
          ? ''
          : `
      padding-right: 30px;
      padding-left: 30px;
      @media (min-width: ${Breakpoints.b560}px) {
        padding-right: ${theme.size.grid002} ;
        padding-left: ${theme.size.grid002} ;
      }
      @media (min-width: ${Breakpoints.b1280}px) {
        padding-right: ${theme.size.grid002};
        padding-left: ${theme.size.grid002} ;
      }`
      }`}
`;

export const StyledStickyHeader: any = styled.div<StyledStickyHeaderProps>`
  ${({ active, activeModal }: any) => {
    const lineHeight = '24px';
    return `

    padding: 0;

    @media(max-width: ${Breakpoints.b560}px) {
      padding: 0 20px;
    }

    &.down {
      @media (min-width: ${Breakpoints.default}px) {
        --sticky-bar-height-down: calc(16px + ${lineHeight} + 12px + 0px);
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 32px);
      }
      @media (min-width: ${Breakpoints.b560}px) {
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 40px);
      }
      @media (min-width: ${Breakpoints.b960}px) {
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 48px);
      }
      @media (min-width: ${Breakpoints.b1920}px) {
        --sticky-bar-height-down: calc(24px + ${lineHeight} + 12px + 0px);
        --sticky-bar-height-up: calc(24px + ${lineHeight} + 12px + 56px);
      }
    }
    &.up {
      @media (min-width: ${Breakpoints.default}px) {
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 32px);
        --sticky-bar-height-down: calc(16px + ${lineHeight} + 12px + 0px);
      }
      @media (min-width: ${Breakpoints.b560}px) {
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 40px);
      }
      @media (min-width: ${Breakpoints.b960}px) {
        --sticky-bar-height-up: calc(16px + ${lineHeight} + 12px + 48px);
      }
      @media (min-width: ${Breakpoints.b1920}px) {
        --sticky-bar-height-up: calc(24px + ${lineHeight} + 12px + 56px);
      }
    }
      ${
        !active && !activeModal
          ? `transition: transform 0.3s ease-in;
        background-color: white;
        transform: translateY(0px);
        @keyframes fadeUp {
          from {margin-top:var(--sticky-bar-height-down) ;}
          to {margin-top:var(--sticky-bar-height-up);}
        }
        @keyframes fadeDown {
          from {margin-top:var(--sticky-bar-height-up) ;}
          to {margin-top:var(--sticky-bar-height-down);}
        }
        ${
          !activeModal
            ? `
            &.down {
              margin-top: var(--sticky-bar-height-down);
              -webkit-animation: fadeDown  0.4s ease-in 0s;
              -moz-animation: fadeDown  0.4s ease-in 0s;
              animation: fadeDown  0.4s ease-in 0s;
            }
            &.up {
              margin-top: var(--sticky-bar-height-up);
              -webkit-animation: fadeUp  0.3s ease-in 0s;
              -moz-animation: fadeUp 0.3s ease-in 0s;
              animation: fadeUp  0.3s ease-in 0s ;
            }
          `
            : ''
        }
  `
          : `width: 100%;`
      }
      @media (min-width: ${Breakpoints.b560}px) {
        ${
          !active
            ? `transition: transform 0.3s ease-in;
          background-color: white;
          transform: translateY(0px);
          @keyframes fadeUp {
            from {margin-top:var(--sticky-bar-height-down) ;}
            to {margin-top:var(--sticky-bar-height-up);}
          }
          @keyframes fadeDown {
            from {margin-top:var(--sticky-bar-height-up) ;}
            to {margin-top:var(--sticky-bar-height-down);}
          }

          &.down {
            margin-top: var(--sticky-bar-height-down);
            -webkit-animation: fadeDown  0.4s ease-in 0s;
            -moz-animation: fadeDown  0.4s ease-in 0s;
            animation: fadeDown  0.4s ease-in 0s;
          }
          &.up {
            margin-top: var(--sticky-bar-height-up);
            -webkit-animation: fadeUp  0.3s ease-in 0s;
            -moz-animation: fadeUp 0.3s ease-in 0s;
            animation: fadeUp  0.3s ease-in 0s ;
          }
    `
            : `width: 100%;`
        }
      }
`;
  }}
`;

export const StyledStickyHeaderContainer = styled.div<StyledStickyHeaderProps>`
  ${({ active, activeModal, bottomShadow }: any) => {
    return !active && !activeModal
      ? `
          position: fixed;
          z-index: ${activeModal ? '2' : '1'};
          width: 100%;
          top: 0;
          background-color: white;
          transform: translateY(0px);
          opacity: 1;
          padding-right: 100px;
          box-shadow: ${
            bottomShadow ? '0 4px 28px -6px rgba(106, 118, 125, 0.5)' : 'none'
          };

          @media (max-width: ${Breakpoints.b960}px) {
            padding-right: 0;
            z-index: 1;
          }

          @media (min-width: ${Breakpoints.b1280}px) {
            z-index: 1;
            padding-right: 100px;
          }
        `
        :
        `
          width: 100%;
        `;
  }}
`;
