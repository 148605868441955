import React from 'react';

import { Breakpoints, useIsMobile } from '@vw-marketing/us-components';

import {
  StyledBottomSection,
  StyledLeftContainer,
  StyledMiddleSection,
  StyledRightContainer,
  StyledSalesSkeletonWrapper,
  StyledTopSection,
} from './styled';
import { SkeletonBox } from '../../../components/skeleton-box';

export const SalesEventSkeletonCard = () => {
  const isTablet: boolean = useIsMobile(Breakpoints.b1920);

  return (
    <StyledSalesSkeletonWrapper>
      <StyledTopSection>
        <SkeletonBox isSalesEvent={true} height="12px" width="64px" />
        <SkeletonBox isSalesEvent={true} height="12px" width="112px" />
      </StyledTopSection>
      <StyledMiddleSection>
        <StyledLeftContainer>
          <SkeletonBox
            isSalesEvent={true}
            height='44px'
            width={isTablet ? '290px' : '450px'}
            responsiveWidth={{mobile: '280px', tablet: '280px'}}
            responsiveHeight={{mobile: '32px', tablet: '32px'}}
          />
          <SkeletonBox
            isSalesEvent={true}
            height='44px'
            width={isTablet ? "246px" : '390px'}
            responsiveWidth={{mobile: '180px', tablet: '240px'}}
            responsiveHeight={{mobile: '32px', tablet: '44px'}}
          />
        </StyledLeftContainer>
        <StyledRightContainer>
          <SkeletonBox
            isSalesEvent={true}
            height='16px'
            width={isTablet ? "246px" : '390px'}
            responsiveWidth={{mobile: '200px', tablet: '200px'}}
            responsiveHeight={{mobile: '12px', tablet: '16px'}}
          />
          <SkeletonBox
            isSalesEvent={true}
            height='16px'
            width={isTablet ? "290px" : '450px'}
            responsiveWidth={{mobile: '250px', tablet: '240px'}}
            responsiveHeight={{mobile: '12px', tablet: '16px'}}
          />
          <SkeletonBox
            isSalesEvent={true}
            height='16px'
            width={isTablet ? "200px" : '330px'}
            responsiveWidth={{mobile: '180px', tablet: '160px'}}
            responsiveHeight={{mobile: '12px', tablet: '16px'}}
          />
          <div style={{ marginTop: 22 }}>
            <SkeletonBox isSalesEvent={true} height="12px" width={isTablet ? "144px" : '174px'} />
          </div>
        </StyledRightContainer>
      </StyledMiddleSection>
      <StyledBottomSection>
        <SkeletonBox isSalesEvent={true} height="32px" width={isTablet ? "130px" : '180px'} />
        <SkeletonBox isSalesEvent={true} height="20px" width={isTablet ? "110px" : '140px'} />
      </StyledBottomSection>
    </StyledSalesSkeletonWrapper>
  );
};
