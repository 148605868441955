import { Breakpoints, styled } from '@vw-marketing/us-components';

export const NewStyleCarouselContainer: any = styled.div`
  ${({isModalMode = false}) => {
    return `
      margin-bottom: 50px;
      width: 100%;

      @media (min-width: ${Breakpoints.b960}px) {
        margin-bottom: 60px;
      }
      ${!isModalMode && `
        .scroll-buttons-wrapper {
          width: calc(100% - 8.3vw);

          @media (max-width: ${Breakpoints.b960}px) {
            width: 100%;
          }
        }
      `}
    `
  }}
`;
