import { gql } from '@apollo/client';

export const PRODUCT_NATIONAL_OFFER_QUERY = gql`
  query productNationalOfferQuery($modelName: String!) {
    getNationalOffersByModel(modelName: $modelName) {
      __typename
      dealAdditionalTerms
      dealCarModel
      dealCarModelName
      dealCarTrim
      dealCarYear
      dealCopy
      dealEndDate
      dealExclusions
      dealId
      dealLegal
      dealLocaleType
      dealOfferTypes
      dealPricePerMonth
      dealPriority
      dealSubtitle
      dealTitle

      offerLegal @client
      offerCopy @client
      localeType @client
      offerTitle @client
      offerSubtitle @client
      trimInventoryName @client

      dealCustomImageName
      dealDaaCode
      dealDaaName
      dealLocaleTypeOrder
      dealRate
      dealRegionCode
      dealRegionName
      dealStartDate
      dealTerm
      dealerCode
      dealerName
      stateCode
      stateName
      dealCustomImageLocation
    }
  }
`;
