import { Breakpoints, styled } from "@vw-marketing/us-components";

export const StyledSalesSkeletonWrapper: any = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.dynamic0100};
  justify-content: center;

  @media (max-width: 559px) {
    padding: ${({ theme }) => theme.size.dynamic0050};
  }

  @media (min-width: ${Breakpoints.b560}px) {
    padding: ${({ theme }) => theme.size.dynamic0100};
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: ${({ theme }) => theme.size.dynamic0040};
  }
`;

export const StyledTopSection: any = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media (max-width: 380px) {
    padding: 0;
  }
`;

export const StyledMiddleSection: any = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 380px){
    padding: 0;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    flex-wrap: wrap;
  }
`;

export const StyledLeftContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: column;
  height: 100%;
`;
  
  export const StyledRightContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

`;

export const StyledBottomSection: any = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: start;
  height: 100%;
  width: 100%;
  
  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-top: 12px;
  }

  @media (max-width: ${Breakpoints.b1280}px) {
    padding-top: ${({ theme }) => theme.size.dynamic0020};
    padding-left  ${({ theme }) => theme.size.dynamic0100};
  }
    
  @media (max-width: 380px){
    padding: 0;
  }

`;