import { makeVar } from '@apollo/client';

import { CarModelConfig } from '../typing/main';
import { CategoryModels } from '../typing/model';
import { DealerModel, ModelTag } from '../hooks-store/typings/incentive-store';
import { ModalDisplayMode } from '../typing/modal-display';
import { Offer } from '../typing/offer';

export const modelsConfigVar = makeVar<CategoryModels[]>([]);
export interface GlobalModelData {
  modelName?: string;
  year: number;
  slug?: string;
}

export const globalModelData = makeVar<GlobalModelData | undefined>(undefined);

export const modelConfiguration = makeVar<CarModelConfig | undefined>(undefined);
interface ModalsData {
  active: boolean;
  dealer?: DealerModel;
  mode?: ModalDisplayMode;
  model?: CarModelConfig;
  modelOffersIDs?: number[];
  modelTag?: ModelTag;
  offer?: Offer;
  urlRedirect?: string;
}

export const modalsData = makeVar<ModalsData>({ active: false });
