import { Breakpoints, styled } from "@vw-marketing/us-components";

export const StyledOffersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 66px 8px 4.2vw;

  @media (max-width: ${Breakpoints.b1280}px) {
    padding-left: 8.4vw;
  }

  @media (max-width: ${Breakpoints.b960}px) {
    padding-left: 13vw;
  }
`;

export const StyledGalleryWrapper: any = styled.div`
  ${({ theme }) => `
      align-items: start;
      display: flex;
      justify-content: start;
      width: 100%;

      @media (max-width: ${Breakpoints.b1280}px) {
        background: ${theme.colors.gradient.top};
        background-size: 100% calc(50vh + (1280px - 100vw) * 0.2);
        background-repeat: no-repeat;
        background-position: bottom;
        height: calc(100vh - 144px);
        width: 100vw;
      }

      @media (max-width: ${Breakpoints.b560}px){
        background-size: 100% calc(65vh + (560px - 100vw) * 0.3);
      }

      @media (max-width: 380px){
        background-size: 100% calc(65vh + (560px - 100vw) * 0.2);
      }
  `}
`;

export const StyledSalesOfferCardWrapper = styled.div`
  ${({ theme }) => {
    return `
      align-items: center;
      background-size: 100%;
      background: ${theme.colors.gradient.top};
      display: flex;
      justify-content: start;
      height: 648px;
      position: relative;
      width: 100%;
      
      @media (max-width: ${Breakpoints.b1280}px) {
        align-items: start;
        background: none;
        flex-direction: column;
        height: calc(100vh - 20vh);
        overflow: hidden;
      }

      @media (max-width: 380px) {
        height: calc(100vh - 30vh);
      }
    `;
  }}
`;

export const StyledSalesImageWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`;

export const StyledLoadingCard = styled.div``;

export const StyledImageCard = styled.div`
  align-self: center;
  aspect-ratio: 5/3;
  background-color: lightgray;
  display: flex;
  flex-basis: 60%;
  justify-content: start;
  min-height: calc(83.33vw / 15);
  max-height: 520px;
  min-width: ${({theme}) => theme.size.grid020};
  padding: 0;
  position: relative;

  @media (min-width: ${Breakpoints.b1280}px) {
    left: -4.15vw;
    min-width: ${({theme}) => theme.size.grid014};
  }

  @media (max-width: 960px) {
    flex-basis: 20%;
  }
`;

export const StyledOfferInformationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 40%;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (max-width: 960px) {
    flex-basis: 80%;
  }
`;