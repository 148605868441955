import {
  FeatureServiceBinder,
  FeatureServiceProviderDefinition,
  SharedFeatureService,
} from '@feature-hub/core';

export interface FeatureAppMemoryStoreV1<TState> {
  setState(state: TState | undefined): void;
  getState(): TState | undefined;
}

interface SharedFeatureAppMemoryStore extends SharedFeatureService {
  readonly ['1.0.0']: FeatureServiceBinder<FeatureAppMemoryStoreV1<unknown>>;
}

export const featureAppMemoryStoreDefinition: FeatureServiceProviderDefinition<SharedFeatureAppMemoryStore> = {
  id: 'feature-app-memory-store',

  create: () => {
    const storedStatesByConsumerId = new Map<string, unknown>();

    return {
      '1.0.0': (consumerId) => ({
        featureService: {
          setState: (state) => {
            storedStatesByConsumerId.set(consumerId, state);
          },

          getState: () => storedStatesByConsumerId.get(consumerId),
        },
      }),
    };
  },
};
