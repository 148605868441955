import { AppConstants } from '../../../utils/app-constants';
import {
  AttributeModel,
  DealerModel,
  OfferInformation,
  TypeMap,
} from '../../typings/incentive-store';

/**
 * Get selected offer by offerId
 * @param dealer
 * @param offersData
 * @param offerId
 */
export const getModalByOfferId = (
  dealer: DealerModel,
  offersData: TypeMap<OfferInformation>,
  offerId: number,
) => {
  let offer;
  const offersKeys = Object.keys(offersData);
  for (var i = 0; i < offersKeys.length; i++) {
    if (offersData[offersKeys[i]].offers.length > 0) {
      const tempOffer = offersData[offersKeys[i]].offers.find(
        (offer: any) => offer.dealId == offerId,
      );
      if (tempOffer) {
        offer = {
          ...tempOffer,
          imageData: offersData[offersKeys[i]].imageData,
          mediaImageUrl: offersData[offersKeys[i]].imageData.modelImage,
          slug: offersData[offersKeys[i]].slug,
          modelKey: offersData[offersKeys[i]].modelKey,
          modelDisplayName: offersData[offersKeys[i]].modelDisplayName,
        };
        break;
      }
    }
  }
  if (offer) {
    return {
      active: true,
      offer,
      dealer,
      formType: AppConstants.OfferDetailsFormType,
      formName: AppConstants.OfferDetailsFormName,
    };
  }
  return undefined;
};

const updateFilters = (
  existingFilters: AttributeModel[],
  newFilter: AttributeModel,
) => {
  const updatedFilters = existingFilters.filter(
    filter => filter.filterKey !== 'offers',
  );
  updatedFilters.push(newFilter);
  return updatedFilters;
};

export const handleNewFilters = (
  existingFilters: AttributeModel[],
  filter: AttributeModel,
) => {
  let updatedFilters = [...existingFilters];

  if (filter.filterKey === 'special-event' || filter.filterKey === 'offers') {
    updatedFilters = updatedFilters.filter(
      ({ filterKey }) =>
        filterKey !==
        (filter.filterKey === 'special-event' ? 'offers' : 'special-event'),
    );

    // Find if an 'offers' filter already exists to replace
    const existingFilterIndex = updatedFilters.findIndex(
      ({ filterKey }) => filterKey === filter.filterKey,
    );
    if (existingFilterIndex !== -1) {
      updatedFilters[existingFilterIndex] = filter;
    } else if (filter.value !== 'all') {
      updatedFilters.push(filter);
    }
  } else {
    updatedFilters = updateFilters(existingFilters, filter);
  }

  return updatedFilters;
};
