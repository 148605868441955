import { Breakpoints, styled } from "@vw-marketing/us-components";

export const StyledSalesHome: any = styled.div`
  ${({ theme }) => {
    return `
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      heigh: 100vh;

      @media (min-width: ${Breakpoints.b1280}px) {
        padding: ${theme.size.dynamic0200};
      }
    `
  }}
`;

export const StyledSalesEventWrapper: any = styled.div`
  ${() => {
    return `
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 28px;
      width: 100%;
      height: 100%;
    `;
  }}
`;

export const StyledSalesEventContainer: any = styled.div`
  ${({ theme }) => {
    return `
      display: flex;
      justify-content: center;
      width: ${theme.size.grid012};

      @media (min-width: ${Breakpoints.b560}px) {
        width: ${theme.size.grid0010};
      }
    `;
  }}
`;

export const SalesEventLogoWrapper: any = styled.div`
  ${({theme}) => {
    return`
      img {
        max-height: ${theme.size.dynamic0350};
        max-width: ${theme.size.grid012};

        @media: (min-width: ${Breakpoints.b960}px) {
          max-width: ${theme.size.grid007};
        }
      }
    `;
  }}
`; 