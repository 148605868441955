import { Infer, array, enums, is, object, optional, string } from 'superstruct';

import { useFeatureServices } from './use-feature-services';

const Content = object({
  bodyType: optional(string()),
  enableIndirectShowroomTrims: optional(enums(['true', 'false', ''])),
  featureAppFeatureFlags: optional(array(object({ featureAppName: string(), featureFlags: string() }))),
  featureFlags: optional(string()),
  salesEventLogoURL: optional(string())
});

export type FeatureAppContent = Infer<typeof Content>;

export function useFeatureAppContent(): FeatureAppContent {
  const { 'fa-content-service': contentService } = useFeatureServices();
  const content = contentService?.content;

  return is(content, Content) ? content : {};
}
