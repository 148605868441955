import {
  TextCustomProps,
  TextAppearance,
  TextSize,
  TextWeight,
  TextAlignment,
  TextLineHeight,
  TextTag,
  TextColor,
} from '@vw-marketing/us-components';

export const textStyling: TextCustomProps = {
  appearance: TextAppearance.copy100,
  style: {
    fontSize: TextSize.TextSize14,
    fontWeight: TextWeight.bold,
    textAlign: TextAlignment.center,
    lineHeight: TextLineHeight.TextLineHeight16,
    color: TextColor.inherit
  },
  tag: TextTag.span,
};
