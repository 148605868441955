import React, { useState } from 'react';

import { ArrowButtons, Breakpoints, Gallery, GallerySlide, Lentils, TextAppearance, TextColor, TextCustom, TextLineHeight, TextSize, TextTag, TextWeight, useIsMobile } from '@vw-marketing/us-components';
import { StyledGalleryWrapper, StyledImageCard, StyledLoadingCard, StyledOfferInformationWrapper, StyledOffersWrapper, StyledSalesImageWrapper, StyledSalesOfferCardWrapper } from './styled';
import { SalesEventSkeletonCard } from '../skeleton-card/SalesEventSkeletonCard';

export const LoadingSalesCard = () => {
    const [_, setActiveIndex] = useState<number>(0);
    const isTablet: boolean = useIsMobile(Breakpoints.b960);

  return (
    <>
      <StyledOffersWrapper>
        <TextCustom
          appearance={TextAppearance.label100}
          tag={TextTag.span}
          style={{
            [Breakpoints.default]: {
              fontSize: TextSize.TextSize20,
              lineHeight: TextLineHeight.TextLineHeight32,
              color: TextColor.primary,
              fontWeight: TextWeight.regular,
            },
          }}
        >
          <TextCustom
            appearance={TextAppearance.label100}
            tag={TextTag.span}
            style={{
              [Breakpoints.default]: {
                fontSize: TextSize.TextSize20,
                lineHeight: TextLineHeight.TextLineHeight32,
                color: TextColor.primary,
                fontWeight: TextWeight.bold,
              },
            }}
          >
            X of X{' '}
          </TextCustom>
          Offers
        </TextCustom>
      </StyledOffersWrapper>
      <StyledGalleryWrapper>
        <Gallery
          id="offers-gallery"
          aria-label="My Gallery"
          ariaLabelledby="gallery-label"
          bottomElements={
            <>
              {!isTablet && (
                <ArrowButtons
                  buttonPreviousAriaLabel="To previous slide"
                  buttonNextAriaLabel="To next slide"
                />
              )}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Lentils />
              </div>
            </>
          }
          fadingEdges={isTablet ? 'auto' : 'none'}
          handleChange={(activeIndex: number) => setActiveIndex(activeIndex)}
        >
          <GallerySlide
            ariaLabelledby="slide 1"
            aria-label="Slide description 1"
          >
            <StyledSalesOfferCardWrapper>
              <StyledSalesImageWrapper>
                <StyledImageCard>
                  <StyledLoadingCard />
                </StyledImageCard>
              </StyledSalesImageWrapper>
              <StyledOfferInformationWrapper>
                <SalesEventSkeletonCard />
              </StyledOfferInformationWrapper>
            </StyledSalesOfferCardWrapper>
          </GallerySlide>
          <GallerySlide
            ariaLabelledby="slide 2"
            aria-label="Slide description 2"
          >
            <StyledSalesOfferCardWrapper>
              <StyledSalesImageWrapper>
                <StyledImageCard>
                  <StyledLoadingCard />
                </StyledImageCard>
              </StyledSalesImageWrapper>
              <StyledOfferInformationWrapper>
                <SalesEventSkeletonCard />
              </StyledOfferInformationWrapper>
            </StyledSalesOfferCardWrapper>
          </GallerySlide>
          <GallerySlide
            ariaLabelledby="slide 3"
            aria-label="Slide description 3"
          >
            <StyledSalesOfferCardWrapper>
              <StyledSalesImageWrapper>
                <StyledImageCard>
                  <StyledLoadingCard />
                </StyledImageCard>
              </StyledSalesImageWrapper>
              <StyledOfferInformationWrapper>
                <SalesEventSkeletonCard />
              </StyledOfferInformationWrapper>
            </StyledSalesOfferCardWrapper>
          </GallerySlide>
        </Gallery>
      </StyledGalleryWrapper>
    </>
  );
};
