import React from 'react';

import { HomePage } from '../../pages/sales-event-home/HomePage';
import HandleDataLoad from '../../components/handle-data-load';
import { AppConstants } from '../../utils/app-constants';
interface SalesEventHomeProps {
  baseUrl?: string;
}

const SalesEventHome: React.FC<SalesEventHomeProps> = ({ baseUrl }) => {
  return (
    <HandleDataLoad
      page={AppConstants.PageSalesEvent as string}
      baseUrl={baseUrl}
    >
      <HomePage />
    </HandleDataLoad>
  );
};

export default SalesEventHome