export interface FormResponseState {
  error: boolean;
  invalid: boolean;
  loading: boolean;
  data?: any;
  submitted?: boolean;
}

export const getSuccessStates = (): FormResponseState => {
  return {
    error: false,
    invalid: false,
    loading: false
  }
};

export const getInvalidStates = (): FormResponseState => {
  return {
    error: false,
    invalid: true,
    loading: false
  }
};

