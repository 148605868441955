import { Breakpoints, styled } from '@vw-marketing/us-components';


export const StyledHeaderGroup: any = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  padding-top: 100px;
  width: 100%;


  @media (max-width: ${Breakpoints.b2560}px) {
    width: calc(100vw - 440px);
  }

  @media (max-width: ${Breakpoints.b1920}px) {
    width: calc(100vw - 400px);
  }

  @media (max-width: ${Breakpoints.b1280}px) {
    width: calc(100vw - 300px);
  }

  @media (max-width: ${Breakpoints.b960}px) {
    width: calc(100vw - 80px);
  }

  @media (max-width: ${Breakpoints.b560}px) {
    width: 100%;
  }
`;

export const StyledHeaderWrapper: any = styled.div`
  margin-bottom: 20px;
  max-width: 430px;
`;

export const StyledSubHeaderWrapper: any = styled.div`
  max-width: 220px;

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 380px;
  }
`;


