import { Breakpoints, styled } from "@vw-marketing/us-components";

export const StyledModalDealerCard = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  @media (min-width: ${Breakpoints.b560}px) {
    grid-template-rows: auto;

    padding-bottom: 52px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    padding-right: 70px;
    border: none;
  }
`;

export const StyledDealerDescriptionWrapper = styled.div`
  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 16px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 10px;
  }
`;

export const StyledDealerNameWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  & > :first-child {
    margin-right: 10px;
  }
`;
export const StyledDealerDetailsWrapper = styled.div`
`;
