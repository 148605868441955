import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';

import {
  ModalDisclaimer,
  ModalDisclaimersService,
} from '@vw-marketing/us-components';
import isBrowser from 'is-browser';

import {
  appendGoogleApiScript,
  appendSectionFAStyle,
  historyListener,
  unsetPagemainZindex,
} from '../../utils/general';
import { DisclaimerConfigurationContext } from '../../hooks/use-disclaimer-configuration';
import { getDisclaimerKeys } from '../../utils/disclaimer';
import { IsNationalOfferContext } from '../../hooks/use-is-national-offer';
import { ModelsConfigContext } from '../../hooks/use-models-config';
import { useDisclaimerReference } from '../../hooks/use-disclaimer-reference';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureAppModel } from '../../hooks/use-featureapp-model';
import { useHistory } from '../../hooks/use-history';
import { useStore } from '../../hooks-store/store';
import TrimModule from '../trim-module';
import ShowroomModule from '../showroom-module';
import SpecialOffersTier1 from '../special-offers-tier-1';
import SpecialOffersModal from '../special-offers-modal';
import SalesEventHome from '../sales-event-home';
import { FeatureAppModelConfig } from '../../typing/main';

export interface FeatureAppStandaloneProps {
  baseUrl?: string;
  done?: (result?: unknown) => void;
}

export const FeatureAppStandalone: React.FC<FeatureAppStandaloneProps> = ({
  baseUrl,
  done,
}) => {
  const [store] = useStore();
  const history = useHistory();
  const disclaimerService = useDisclaimerReference;
  const initialDisclaimerData: ModalDisclaimer = {
    active: false,
  };
  const { mode, activeNationalOffers = true } = useFeatureAppConfig();
  const modelOverview = useFeatureAppModel() ?? ({} as FeatureAppModelConfig);
  const disclaimersConfig = {
    disclaimers: store.fetchedSharedConfigs?.disclaimers
      ? getDisclaimerKeys(
          store.fetchedSharedConfigs.disclaimers,
          disclaimerService,
        )
      : [],
  };

  let toRenderComponent: React.JSX.Element;

  historyListener(history);

  useEffect(() => {
    if (isBrowser) {
      appendGoogleApiScript();
      if (mode === 'tier-1') {
        appendSectionFAStyle();
      }

      // Avoid the navigation menu overlap issue
      unsetPagemainZindex();
    }
  }, []);

  if (!modelOverview || Object.keys(modelOverview).length === 0)
    return undefined;

  switch (mode) {
    case 'trim-module':
      toRenderComponent = <TrimModule />;
      break;
    case 'module':
      toRenderComponent = <ShowroomModule baseUrl={baseUrl} />;
      break;
    case 'tier-1':
      toRenderComponent = <SpecialOffersTier1 baseUrl={baseUrl} />;
      break;
    case 'modal':
      toRenderComponent = <SpecialOffersModal baseUrl={baseUrl} done={done} />;
      break;
    case 'sales-event-home':
      toRenderComponent = <SalesEventHome baseUrl={baseUrl} />;
      break;
    default:
      toRenderComponent = <SpecialOffersTier1 />;
      break;
  }

  return (
    <ModelsConfigContext.Provider value={modelOverview}>
      <DisclaimerConfigurationContext.Provider value={disclaimersConfig}>
        <ModalDisclaimersService disclaimerData={initialDisclaimerData}>
          <IsNationalOfferContext.Provider
            value={activeNationalOffers && mode === 'tier-1'}
          >
            <Router history={history}>{toRenderComponent}</Router>
          </IsNationalOfferContext.Provider>
        </ModalDisclaimersService>
      </DisclaimerConfigurationContext.Provider>
    </ModelsConfigContext.Provider>
  );
};
