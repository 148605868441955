import React from 'react';
import { FeatureAppModelConfig } from '../typing/main';

export const ModelsConfigContext = React.createContext<
  FeatureAppModelConfig | undefined
>(undefined);

export function useModelsConfig(): FeatureAppModelConfig {
  const modelsConfig = React.useContext(ModelsConfigContext);
  if (!modelsConfig) {
    throw new Error('No defined models config');
  }
  return modelsConfig;
}
