import { History } from 'history';
import map from 'lodash/map';
import pick from 'lodash/pick';
import queryString from 'query-string';
import { SpecialEventConfig, SpecialEventFilterOption } from '../../..';
import {
  addUrlParam,
  getHistorySearch,
  getUrlParam,
} from '../../../utils/navigation-url';
import {
  AttributeModel,
  FilterAttributeModel,
} from '../../typings/incentive-store';
import { initialFilterAttributes } from '../initial-state';

/**
 * Update the url with the applied filters
 * @param appliedFilters Filters applied in the result page
 * @param history Router parameter
 */
export const filtersToUrl = (
  appliedFilters: AttributeModel[],
  history: History,
) => {
  const zip = getUrlParam(history, 'zip');

  // Create a hash with the filterKey (filterKey: [value, value])
  const filters = appliedFilters.reduce(
    (next: any, { filterKey, text }: any) => ({
      ...next,
      [filterKey]: [...(next[filterKey] || []), text],
    }),
    {},
  );

  const url = Object.keys(filters)
    .map(key => `${key}=${encodeURIComponent(filters[key].join(','))}`)
    .join('&');
  const { location } = history;
  const path = `${location.pathname}?${url}`;
  history.replace(path);

  if (zip) {
    addUrlParam(history, 'zip', zip);
  }
};

/**
 * Get the initialFilterAttributes that match with the filterKey
 * @param key Is the filterKey of the filter attributes
 */
const getConfig = (key: string): FilterAttributeModel | undefined =>
  initialFilterAttributes.find(
    (filterMap: FilterAttributeModel) => filterMap.filterKey === key,
  );

const getSpecialEventValueByLabel = (
  specialEvents?: SpecialEventFilterOption[],
  label?: string,
) => {
  if (specialEvents?.length) {
    const option = specialEvents.find(
      (option: SpecialEventFilterOption) => option.text === label,
    );
    if (option) {
      return option.value;
    }
  }
  return '';
};

/**
 * Convert URL parameters to apply filters
 * @param history Route history
 */
export const urlToFilters = (
  history: History,
  specialEvent?: SpecialEventConfig,
) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  let filtersToSearch = initialFilterAttributes;
  if (specialEvent?.filterOptions?.length) {
    filtersToSearch.push({
      name: 'Special Event',
      filterKey: 'special-event',
      attributes: [],
    });
  }
  const baseObj = pick(query, map(filtersToSearch, 'filterKey'));

  const filtersObj: any = Object.keys(baseObj).reduce((next, key: string) => {
    const config = getConfig(key);
    const value = Array.isArray(baseObj[key])
      ? baseObj[key]
      : (baseObj[key] as string).split(',');

    if (config) {
      return {
        ...next,
        [config.filterKey]: value,
      };
    }

    return {
      ...next,
    };
  }, {});
  const filters = Object.keys(filtersObj).reduce(
    (next: any, key: string) => [
      ...next,
      ...(filtersObj[key] as string[]).map((value: string) => {
        value = value === 'Finance' ? 'apr' : value;
        return {
          filterKey: key,
          value:
            key === 'special-event' || value === 'Sales Event'
              ? getSpecialEventValueByLabel(specialEvent?.filterOptions, value)
              : value === 'Special Programs'
              ? 'other'
              : value.toLowerCase(),
          text: value,
        };
      }),
    ],
    [],
  );
  return filters;
};
