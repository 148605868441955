import { styled, Breakpoints } from '@vw-marketing/us-components';
import { keyframes } from 'styled-components';

// Define an interface for responsive styles
interface ResponsiveStyles {
  mobile?: string; // Width/height for mobile devices
  tablet?: string; // Width/height for tablet devices
}

interface SkeletonBoxProps {
  width: string; // Default width of the SkeletonBox
  height: string; // Default height of the SkeletonBox
  isSalesEvent?: boolean; // Flag for the new Sales Event Design
  responsiveWidth?: ResponsiveStyles; // Responsive widths for different screen sizes
  responsiveHeight?: ResponsiveStyles; // Responsive heights for different screen sizes
}

const skeletonAnimation = keyframes`
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const defaultGradiend = `
  linear-gradient(
    60deg,
  rgba(255, 255, 255, 0) 33.333%,
  rgba(255, 255, 255, 0.5) 50%,
  rgba(255, 255, 255, 0) 66.666%
  )
  0% 0% / 300%,
rgb(223, 228, 232);
`

const salesGradient = `
  linear-gradient(
    60deg,
    rgb(0, 67, 122) 33.333%,
    rgba(255, 255, 255, 0.05) 50%,
    rgb(0, 67, 122) 66.666%
  )
  0% 0% / 300%,
rgb(0, 67, 122);
`

export const StyledSkeletonBox = styled.div<SkeletonBoxProps>`
  background: ${({isSalesEvent}) => isSalesEvent ? salesGradient : defaultGradiend}
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: ${skeletonAnimation} 2s linear infinite;

  @media (max-width: ${Breakpoints.b560}px) {
    width: ${({ responsiveWidth, width }) => responsiveWidth?.mobile || width};
    height: ${({ responsiveHeight, height }) =>
      responsiveHeight?.mobile || height};
  }

  @media (min-width: ${Breakpoints.b560}px) and (max-width: ${Breakpoints.b960}px) {
    width: ${({ responsiveWidth, width }) => responsiveWidth?.tablet || width};
    height: ${({ responsiveHeight, height }) =>
      responsiveHeight?.tablet || height};
  }
`;
