interface AuthorizationHeader {
  readonly Authorization: string;
}

export function createAuthorizationHeader(
  username?: string,
  password?: string,
): AuthorizationHeader | undefined {
  if (!password || !username) {
    return undefined;
  }

  return {
    Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
      'base64',
    )}`,
  };
}
