import { StyledSkeletonBox } from './styled';
import React from 'react';

// Define the interface for responsive styles
interface ResponsiveStyles {
  mobile?: string; // Width/height for mobile devices
  tablet?: string; // Width/height for tablet devices
}

/**
 * A SkeletonBox component that serves as a placeholder for loading content.
 * It displays a rectangular box with customizable width and height.
 *
 * @param {SkeletonBoxProps} props - The properties for the SkeletonBox component.
 * @param {string} [props.width] - The width of the skeleton box (e.g., '200px'). Defaults to '100%'.
 * @param {string} [props.height] - The height of the skeleton box (e.g., '100px'). Defaults to '100%'.
 * @param {ResponsiveStyles} [props.responsiveWidth] - An object defining responsive widths for different screen sizes.
 * @param {ResponsiveStyles} [props.responsiveHeight] - An object defining responsive heights for different screen sizes.
 *
 * @example
 * // Basic usage of SkeletonBox with specified dimensions
 * <SkeletonBox width="200px" height="100px" />
 *
 * @example
 * // Usage with responsive widths and heights
 * <SkeletonBox
 *   width="200px"
 *   height="100px"
 *   responsiveWidth={{ mobile: '90%', tablet: '80%' }}
 *   responsiveHeight={{ mobile: '80px', tablet: '60px' }}
 * />
 *
 * @returns {JSX.Element} The rendered SkeletonBox component.
 */
interface SkeletonBoxProps {
  readonly width?: string; // Width of the SkeletonBox (e.g., '200px')
  readonly height?: string; // Height of the SkeletonBox (e.g., '100px')
  readonly isSalesEvent?: boolean; // Flag for the new Sales Event Design
  readonly responsiveWidth?: ResponsiveStyles; // Responsive widths for mobile and tablet
  readonly responsiveHeight?: ResponsiveStyles; // Responsive heights for mobile and tablet
}

export const SkeletonBox: React.FC<SkeletonBoxProps> = ({
  height = '100%',
  width = '100%',
  isSalesEvent = false, // Flag for the new Sales Event Design
  responsiveWidth,
  responsiveHeight,
}) => {
  return (
    <StyledSkeletonBox
      width={width}
      height={height}
      isSalesEvent={isSalesEvent}
      responsiveWidth={responsiveWidth}
      responsiveHeight={responsiveHeight}
    />
  );
};
