import React, { useCallback } from 'react';
import {
  Breakpoints,
  ChevronDown,
  TextAppearance,
  TextColor,
  TextCustom,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import {
  OffersBoxButton,
  OffersBoxContainer,
  OffersCollapseContainer,
  StyledIcon,
  StyledLabel,
  StyledSeparatorLine,
} from './styles';
import { useTrackingManager } from '../../hooks/use-tracking-manager';
import { onHandleBasicAccordionAction } from '../../tagging/tagging-helpers';

interface OffersBoxProps {
  readonly title: string;
  readonly children: React.ReactNode;
}
type collapseState = 'visible' | 'hidden';

const titleTextStyle: TextCustomProps = {
  appearance: TextAppearance.label100,
  style: {
    [Breakpoints.default]: {
      fontSize: TextSize.TextSize12,
      lineHeight: TextLineHeight.TextLineHeight16,
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
    },
    [Breakpoints.b960]: {
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight20,
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
    },
  },
};

const OffersBox: React.FC<OffersBoxProps> = props => {
  const { title, children } = props;
  const [shownOffers, setShownOffers] = React.useState<collapseState>(
    'visible',
  );
  const trackingManager = useTrackingManager();
  const onHandleShowOffers = useCallback(() => {
    setShownOffers(prevState =>
      prevState === 'hidden' ? 'visible' : 'hidden',
    );
    trackingManager &&
      onHandleBasicAccordionAction(shownOffers !== 'visible', trackingManager);
  }, [shownOffers, trackingManager]);

  const MemoizedChildren = React.memo(
    ({ children }: { children: React.ReactNode }) => <>{children}</>,
  );

  return (
    <OffersBoxContainer className="available-offers_container">
      <OffersBoxButton onClick={onHandleShowOffers}>
        <StyledLabel className="offers-title">
          <TextCustom {...titleTextStyle} tag={TextTag.p}>
            {title}
          </TextCustom>
        </StyledLabel>

        <StyledIcon className="offers-icon">
          <div className={`animation ${shownOffers}`}>
            <ChevronDown variant="small" />
          </div>
        </StyledIcon>
      </OffersBoxButton>
      <StyledSeparatorLine className="separator-line"></StyledSeparatorLine>
      <OffersCollapseContainer className={`offers-list ${shownOffers}`}>
        <MemoizedChildren>{children}</MemoizedChildren>
      </OffersCollapseContainer>
    </OffersBoxContainer>
  );
};

export default OffersBox;
