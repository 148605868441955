import {
  TextSize,
  TextLineHeight,
  TextColor,
  TextCustomProps,
  TextAppearance,
  TextTag,
  createTagOverride,
  TextCustom,
  TextType,
  TextWeight,
  Breakpoints,
} from '@vw-marketing/us-components';


export const vinTextStyle: TextCustomProps = {
  appearance: TextAppearance.copy200,
  tag: TextTag.span,
  style: {
    color: TextColor.secondary,
  },
};

const descriptionTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      fontSize: TextSize.TextSize18,
      lineHeight: TextLineHeight.TextLineHeight28,
      fontType: TextType.Headline,
    },
    [Breakpoints.b1280]: {
      fontSize: TextSize.TextSize24,
      lineHeight: TextLineHeight.TextLineHeight28,
      fontType: TextType.Headline,
    },
  },
};
const descriptionStrongTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  tag: TextTag.strong,
  style: {
    [Breakpoints.default]: {
      fontSize: TextSize.TextSize18,
      lineHeight: TextLineHeight.TextLineHeight28,
      fontType: TextType.Headline,
    },
    [Breakpoints.b1280]: {
      fontSize: TextSize.TextSize24,
      lineHeight: TextLineHeight.TextLineHeight28,
      fontType: TextType.Headline,
    },
  },
};

export const descriptionOverrides = {
  p: createTagOverride(TextCustom, descriptionTextStyle),
  strong: createTagOverride(TextCustom, descriptionStrongTextStyle),
};

export const dealerNameTextStyle = {
  appearance: TextAppearance.headline300,
  tag: TextTag.p,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize12,
      lineHeight: TextLineHeight.TextLineHeight20,
    },
    [Breakpoints.b1280]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize24,
      lineHeight: TextLineHeight.TextLineHeight28,
    },
  },
};
