export interface OfferTypeModel {
  readonly offerType: string;
  readonly offerName: string;
}

export const newLocalOfferType: OfferTypeModel[] = [
  { offerType: 'all', offerName: 'All Offers' },
  { offerType: 'apr', offerName: 'Finance' },
  { offerType: 'lease', offerName: 'Lease' },
  { offerType: 'other', offerName: 'Special Programs' },
];
