import {Text, TextAppearance, styled, Breakpoints} from '@vw-marketing/us-components';
import React from 'react';
import {SpinnerIcon} from '../../components/icons/spinner-icon';

interface LoaderModalProps {
  readonly label?: string;
}

const StyledModalWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledSpinnerContainer: any = styled.div`
  display: inline-block;
`;

const StyledSpinnerWrapper: any = styled.div`
  display: flex;
  background: #fff;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2);

  & > :first-child {
    margin-bottom: 20px;
  }
`;

export const LoaderSpinnerModal: React.FC<LoaderModalProps> = (
  props,
): JSX.Element => {
  const {label} = props;

  return (
    <StyledModalWrapper className="modal-spinner-wrapper">
      <StyledSpinnerContainer>
        <StyledSpinnerWrapper>
          <SpinnerIcon iconSize={60} />
          <Text appearance={TextAppearance.copy200}>
            {!label ? label : 'Loading...'}
          </Text>
        </StyledSpinnerWrapper>
      </StyledSpinnerContainer>
    </StyledModalWrapper>
  );
};
