import React from 'react';

import {
  Breakpoints,
  CustomImage,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

import { AppConstants } from '../../utils/app-constants';
import {
  SalesEventLogoWrapper,
  StyledSalesEventContainer,
  StyledSalesEventWrapper,
  StyledSalesHome,
} from './styled';
import { useFeatureAppContent } from '../../hooks/use-feature-app-content';
import { LoadingSalesCard } from './loading-card/LoadingSalesCard';

export const HomePage = () => {
  const { salesEventLogoURL } = useFeatureAppContent();

  return (
    <StyledSalesHome>
      <StyledSalesEventWrapper>
        <StyledSalesEventContainer>
          {salesEventLogoURL ? (
            <SalesEventLogoWrapper>
              <CustomImage
                src={salesEventLogoURL}
                alt="Sales_Event_Logo"
                srcPlaceholder={AppConstants.PlaceHolder480x216 as string}
              />
            </SalesEventLogoWrapper>
          ) : (
            <TextCustom
              appearance={TextAppearance.headline300}
              tag={TextTag.h1}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize28,
                  lineHeight: TextLineHeight.TextLineHeight32,
                  color: TextColor.inherit,
                  fontWeight: TextWeight.regular,
                },
              }}
            >
              Special Offers
            </TextCustom>
          )}
        </StyledSalesEventContainer>
      </StyledSalesEventWrapper>
      <LoadingSalesCard />
    </StyledSalesHome>
  );
};
