interface ShowroomMapPagesModel {
  [key: string]: PageModel;
}

interface PageModel {
  readonly pageName: string;
}

export const showroomMapPages: ShowroomMapPagesModel = {
  acs: {
    pageName: 'atlas-cross-sport'
  },
  tiguan: {
    pageName: 'tiguan'
  },
  atlas: {
    pageName: 'atlas'
  },
  jetta: {
    pageName: 'jetta'
  },
  passat: {
    pageName: 'passat'
  },
  golfgti: {
    pageName: 'golf-gti'
  },
  gsw: {
    pageName: 'golf-sportwagen'
  },
  alltrack: {
    pageName: 'golf-alltrack'
  },
  golf: {
    pageName: 'golf'
  },
  beetle: {
    pageName: 'beetle'
  },
  beetleconvertible: {
    pageName: 'beetle-convertible'
  },
  golfr: {
    pageName: 'golf-r'
  },
  egolf: {
    pageName: 'e-golf'
  },
  arteon: {
    pageName: 'arteon'
  },
  jettagli: {
    pageName: 'jetta-gli'
  },
  id4: {
    pageName:'id-4'
  },
  taos: {
    pageName:'taos'
  },
  default: {
    pageName: 'tiguan'
  }
};
