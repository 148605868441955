import { ContentLayerProps } from "@vw-marketing/us-components";

import { CarModelConfig } from "./main";
import { DealerModel, ModelTag } from "../hooks-store/typings/incentive-store";
import { ModalTrimModuleDetailsTranslations } from "./translations";
import { Offer } from "./offer";

export enum ModalDisplayMode {
  'TRIM_MODULE_DETAILS' = 'trim_module_details',
}
export interface TrimModuleDetailsLayerState extends ContentLayerProps {
  readonly dealer?: DealerModel,
  readonly index: number;
  readonly model?: CarModelConfig;
  readonly modelOffersIDs?: number[];
  readonly modelTag?: ModelTag;
  readonly offer?: Offer,
  readonly text: string;
  readonly translations: ModalTrimModuleDetailsTranslations;
  readonly urlRedirect?: string;

  readonly onCloseHandler?: () => void;
  readonly onLoadHandler?: () => void;
}
