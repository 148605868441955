import 'isomorphic-fetch';
import {
  getInvalidStates,
  getSuccessStates,
  FormResponseState
} from './helpers/response-states';

interface RequestOptions {
  readonly faServicesUrl: string;
  readonly serviceConfigsServiceConfig?: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly userParams: any;
}

function createSubmitUrl(params: URLSearchParams, urlOrigin: string): string {

  return `${urlOrigin}/s2f?${params.toString()}`;
}

export async function submitLead({
  faServicesUrl,
  serviceConfigsServiceConfig,
  mockIds,
  mocksBaseUrl,
  userParams
}: RequestOptions): Promise<FormResponseState> {
  const params = new URLSearchParams();

  if (serviceConfigsServiceConfig) {
    params.set('serviceConfigsServiceConfig', serviceConfigsServiceConfig);
  }
  if (mockIds) {
    params.set('mockIds', mockIds);
  }

  if (mocksBaseUrl) {
    params.set('mocksBaseUrl', mocksBaseUrl);
  }

  // params.set('json', JSON.stringify(userParams));
  params.set('queryParamsJson', JSON.stringify(userParams));

  if(userParams.requestType == 'Contact a dealer' || userParams.requestType == "Schedule Test Drive"){
    // only for contact dealer we use V2 api. pending migrate all forms
    params.set('s2fFormPath', `leadsv2`);
  } else {
    params.set('s2fFormPath', `leads`);
  }
  

  const response = await fetch(createSubmitUrl(params, faServicesUrl));
  const result = await response.json();
  if (!result || !result.leadId) {
    console.warn(`Form submit failed, did not get a lead ID`);
    return {
      data: {message: result, code: response.status},
      ...getInvalidStates(),
      submitted: true
    };
  }
  return {
    data: result.leadId,
    ...getSuccessStates(),
    submitted: true
  };
}
